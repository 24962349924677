// 
// logout.scss
//

.logout-checkmark {
    width: 120px;
    margin: 0 auto;
    padding: 20px 0;
}
  
.logout-path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    animation: dash 2s ease-in-out;
}

.logout-spin {
    animation: spin 2s;
    transform-origin: 50% 50%;
}

@keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}


@keyframes text {
    0% {
        opacity: 0; }
    100% {
        opacity: 1;
    }
}